@font-face {
	font-family: 'Maison Neue';
	src: url('fonts/MaisonNeue-Bold.eot');
	src: url('fonts/MaisonNeue-Bold.eot?#iefix') format('embedded-opentype'),
		url('fonts/MaisonNeue-Bold.woff') format('woff'),
		url('fonts/MaisonNeue-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Maison Neue';
	src: url('fonts/MaisonNeue-Light.eot');
	src: url('fonts/MaisonNeue-Light.eot?#iefix') format('embedded-opentype'),
		url('fonts/MaisonNeue-Light.woff') format('woff'),
		url('fonts/MaisonNeue-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Maison Neue';
	src: url('fonts/MaisonNeue-BoldItalic.eot');
	src: url('fonts/MaisonNeue-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('fonts/MaisonNeue-BoldItalic.woff') format('woff'),
		url('fonts/MaisonNeue-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Maison Neue';
	src: url('fonts/MaisonNeue-LightItalic.eot');
	src: url('fonts/MaisonNeue-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('fonts/MaisonNeue-LightItalic.woff') format('woff'),
		url('fonts/MaisonNeue-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}
