@import 'fonts';

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

// iFrame content 
.iframe-content {
	padding: 30px;


	p, div, span {
		font-family: Arial, Helvetica, sans-serif !important;
		font-size: 14px !important;
		line-height: 18px;	
	}
	
	h4 {
		font-weight: bold;
		margin-bottom: 20px;
	}

	strong {
		font-weight: bold;
	}


	header {
		display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-ms-flex-direction: column;
        flex-direction: column;
-webkit-box-pack: center;
-ms-flex-pack: center;
        justify-content: center;
-webkit-box-align: center;
-ms-flex-align: center;
        align-items: center;
        margin-bottom: 30px;

		img {
			display: block;
		}

		p {
			font-style: italic;
			margin-top: 10px;
		}
	}

	.main-content {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;

		article {
			width: 60%;
			margin-right: 30px;
		}

		aside {
			width: 25%;
		}
	}

	.column-content {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;

		div {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			        flex-direction: column;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			        justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			        align-items: center;
			margin-right: 20px;

			& > strong {
				display: block;
				margin-bottom: 10px;
			}

			&:last-child {
				margin-right: 0;
			}

			img {
				margin-bottom: 10px;
			}
		}
	}

	ul {
		list-style-type: disc;
		margin-left: 20px;
		margin-bottom: 20px;
	}

}

// Start of app related styles
.nav-app {
	background-color: #a7a7a7;
	padding: 0 20px 0 0;
	border-bottom: 1px solid #c3c3c3;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 54px;
	z-index: 3;

	.home {
		background-color: #fff;
		color: #929292;
		font-weight: bold;
		font-family: 'Maison Neue';
		letter-spacing: 0.05em;
		text-transform: uppercase;
		text-decoration: none;
    	padding: 10px 15px;
    	border-radius: 5px;

		img {
			width: 14px;
			margin-right: 10px;
		}
	}

	.social {
		border-left: 1px solid #cacaca;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0;
		height: 100%;

		& > div {
			margin-top: 0;
			position: relative;
		}

		.follow {
			button {
				border-right: 1px solid #cacaca;
			}
		}

		.share {
			ul {
				right: 0;
				width: 72px;
			}
		}


		button {
			background-color: #a7a7a7;
			color: #fff;
			display: block;
			border: none;
			font-size: 15px;
			font-weight: bold;
			font-family: 'Maison Neue';
			letter-spacing: 0.05em;
			padding: 19px 20px 17px;
			text-transform: uppercase;
			text-decoration: none;
			outline: none;

			&:hover {
				cursor: pointer;
			}

			i {
				font-size: 10px;
			    position: relative;
			    top: -2px;
			}
		}

		ul {
			background-color: #A6A6A6;
			font-weight: bold;
			font-family: 'Maison Neue';
			position: absolute;
			padding: 18px;
			width: 82px;
			display: none;

			&.shown {
				display: block;
			}

			li {
				border-bottom: 1px dashed #ddd;
				padding-bottom: 10px;
				margin-bottom: 10px;

				&:last-child {
					border-bottom: none;
					padding-bottom: 0;
					margin-bottom: 0;
				}				
			}

			a {
				text-decoration: none;
				color: #fff;
				font-size: 14px;

				i {
					margin-right: 10px;
				}
			}
		}

		#twitter-widget-0 {
			margin-top: 4px;
		}

		.fb-like {
			
		}

		& > span {
			margin-top: 4px;
			margin-left: 8px;

			& > span {
				padding: 2px 2px 0 !important;
				background-color: #0077b5;
    			border-radius: 3px;
			}
		}

		.email-btn {
			font-family: 'Maison Neue';
		    font-size: 11px;
		    background-color: #444;
		    color: #fff;
		    padding: 7px 10px 5px;
		    border-radius: 5px;
		    line-height: 23px;
		    margin-top: 0;
		}
	}
}

// Hide Scroll but Still Scrolls 
section.getapp::-webkit-scrollbar, div.more-info-content::-webkit-scrollbar {
	display: none;
}

// Iframe Styles

.iframe-body {
	padding: 30px;
}

.sidebar-iframe {
	overflow-y: scroll;
}

.sidebar-main, .pq-main {
	background-color: #fff;
	padding: 30px 20px;

	header {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		        justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		        align-items: center;
		margin-bottom: 20px;
	}

	p, ol {
		font-family: 'Maison Neue';
		font-size: 16px;
		font-weight: 300;
		margin-bottom: 30px;
	}

	p {
		line-height: 21px;
	}

	ol {
		list-style-type: decimal;
		margin-left: 20px;

		li {
			margin-bottom: 10px;
			line-height: 21px;
		}
	}

	strong {
		font-family: 'Maison Neue';
		font-weight: bold;
	}

	.icons {
		width: 30px;
	}

	.feedback {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		        justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		        align-items: center;

		img {
			height: 40px;
		}
	}
}

.pq-main {
	height: calc(100vh - 60px);
}

.more-info-content, .home, .getapp {
	padding: 0;
	height: 100%;

	iframe {
		width: 100%;
		height: 100%;

		body {
			margin: 0 !important;
		}
	}
}

.home {
	box-sizing: border-box;
	padding-top: 54px;
}

.more-info-main, .main-home {
	font-family: 'Maison Neue';

	p, ol {
		font-size: 16px;
		font-weight: 300;
		margin-bottom: 30px;
	}
}

.getapp {
	height: 100%;
	box-sizing: border-box;
	padding-top: 54px;
}

// Main SPA Styles

.more-info {
	display: none;

	header {
		padding: 20px;
		border-bottom: 1px dashed #ddd;

		.back {
			font-family: 'Maison Neue';
			text-transform: uppercase;
			text-decoration: none;
			color: #444;

			img {
				margin-right: 15px;
			}
		}
	}

	&.shown {
		display: block;
		height: 100%;
		box-sizing: border-box;
		padding-top: 54px;
	}
}

.main {
	.body {
		height: 100%;

		section {
			display: none;
			height: 100vh;
			overflow: hidden;

			&.loader {
				width: 100%;
				height: 100%;
				box-sizing: border-box;
				padding-top: 54px;

				.loader-wrapper {
					height: calc(100vh - 54px);
					display: flex;
					justify-content: center;
					align-items: center;
				}

				p {
					font-family: Calibri, Arial, sans-serif;
					font-size: 22px;
				}
			}

			&.shown {
				display: block;

			}

			.main-wrapper {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				height: 100vh;
				box-sizing: border-box;
				padding-top: 54px;

				&.hidden {
					display: none;
				}
			}

			&.pqapp {
				aside {
					height: 100%;
				}
			}

			aside {
				width: 33%;
				height: 98%;
				display: -webkit-box;
			    display: -ms-flexbox;
			    display: flex;
			    -webkit-box-orient: vertical;
			    -webkit-box-direction: normal;
			    -ms-flex-direction: column;
			            flex-direction: column;
			    -webkit-box-pack: justify;
			    -ms-flex-pack: justify;
			            justify-content: space-between;

			    .content {
			    	height: 100%;
			    	padding: 0 3px 0 10px;
			    }

			    iframe {
			    	width: 100%;
			    	height: 100%;
			    }

			    footer {
			    	background-color: #fff;
			    	border-top: 1px dashed #ccc;
			    	padding: 15px 35px 15px 20px;
			    	width: 100%;
			    	box-sizing: border-box;

			    	button {
			    		background-color: #909090;
			    		color: #fff;
			    		font-family: 'Maison Neue';
			    		font-weight: bold;
			    		letter-spacing: 0.05em;
			    		text-transform: uppercase;
			    		width: 100%;
			    		font-size: 18px;
					    border: none;
					    padding: 10px 10px 8px;
					    border-radius: 5px;

					    &:hover {
					    	cursor: pointer;
					    }
			    	}
			    }
			}

			.app-content {
				background-color: #fff;
				border-left: 1px solid #a7a7a7;
				width: 69%;
				height: 100%;
				padding: 20px;
				margin-left: -14px;
				box-sizing: border-box;
				position: relative;

				iframe {
					width: 100%;
					height: 97%;
				}
			}
		}
	}
}

.app-specific {
	display: flex;
	border-left: 1px solid #cacaca;
	border-right: 1px solid #cacaca;

	a {
		color: #fff;
		display: block;
		border-right: 1px solid #cacaca;
		font-size: 15px;
		font-weight: bold;
		font-family: 'Maison Neue';
		letter-spacing: 0.05em;
		padding: 22px 15px 17px;
		text-transform: uppercase;
		text-decoration: none;
		outline: none;

		&:last-child {
			border-right: none;
		}

		&.selected {
			background-color: #50E3C2;
			color: #fff;
		}
	}
}